#struktur_organisasi {
    & .pegawai {
        & img.foto_pegawai {
            aspect-ratio: 1 / 1;
            border-radius: 50%;
            width: 80%;
            transition: 0.25s all ease-in-out;
            object-fit: cover;
        }

        &:hover {
            & img.foto_pegawai {
                transform: scale(1.05);
                transition: 0.25s all ease-in-out;
            }
        }
    }



}