.carousel {
    aspect-ratio: 21 / 9;
}

.carousel.has-caption::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 21 / 9;
    z-index: 1;
    background: rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 768px) {
        height: fit-content !important;
    }
}

.carousel-item img {
    aspect-ratio: 21 / 9;
    object-fit: cover;

    @media screen and (max-width: 768px) {
        height: fit-content !important;
    }
}

.carousel-captions {
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    * {
        color: white;
    }

    h1 {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 3rem;
    }

    h4 {
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
        font-size: 1.5rem;
    }

    @media screen and (max-width: 768px) {
        h1 {
            font-size: 1.2rem !important;
        }

        h4 {
            font-size: 0.9rem !important;
        }
    }
}

.carousel .carousel-indicators button {
    display: flex;
    justify-content: center;
    opacity: 1;
    height: 35px;
    width: 20px;
    border: 0 solid;
    background-color: transparent;
}

.carousel .carousel-indicators button .indicator-bullet {
    background-color: transparent;
    margin: auto;
    border-radius: 50%;
    transition: padding .1s linear;
}

.carousel .carousel-indicators button .indicator-bullet * {
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: #d8d8d8;

    @media screen and (max-width: 768px) {
        height: 8px;
        width: 8px;
    }
}

.carousel .carousel-indicators button.active .indicator-bullet * {
    background-color: #ee0404;
}

.carousel-indicator-skeleton {
    width: 100px;

    & .react-loading-skeleton {
        border-radius: 50rem !important;
    }
}

.carousel-inner-skeleton {
    aspect-ratio: 21 / 9;

    & span.react-loading-skeleton {
        aspect-ratio: 21 / 9;
        border-radius: 0 !important;
        line-height: var(--bs-body-line-height) !important;
    }

    @media screen and (max-width: 768px) {
        & span.react-loading-skeleton {
            aspect-ratio: 21 / 9;
        }
    }
}