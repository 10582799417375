#our_solution {
    margin-top: -80px;
    // background-color: #eee;

    .container {
        background-color: #f7f8fd;
        padding: 10px 40px 40px;
        border-radius: 24px 24px 24px 24px;
    }

    @media screen and (max-width: 768px) {
        margin-top: -30px;
    }
}

.services-item {
    background-color: #fff;
    padding: 55px 32px 48px;
    box-shadow: 11px 11px 24px 0 rgba(78, 84, 126, .02), 6px 6px 16px 0 rgba(78, 84, 126, .02), 3px 3px 8px 0 rgba(78, 84, 126, .02), 1px 1px 4px 0 rgba(78, 84, 126, .02), 1px 1px 1px 0 rgba(78, 84, 126, .02);
    border-radius: 12px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;

    img {
        object-fit: cover;
        max-width: 82px;
        aspect-ratio: 1 / 1;
        transition: 0.25s all ease-in-out;
    }

    &:hover {
        img {
            transition: 0.25s all ease-in-out;
            transform: scale(1.1)
        }
    }
}