.header {
    background: #fff;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid #e9edf4;
}

.navbar.navbar-admin {
    background-color: white !important;
    background: white !important;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem;
}

.responsive-navbar.navbar-admin {
    display: initial;
    flex-basis: inherit;
    flex-wrap: inherit;
}

.navbar-collapse.navbar-admin {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.hor-header .container.main-container div:first-child {
    align-items: center !important;
}

@media (min-width: 992px) {
    .responsive-navbar.navbar-admin .navbar-collapse {
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        background: transparent;
    }
}

.navbar-admin .collapse:not(.show) {
    display: none;
    background: #fff;
}

.navbar-admin .collapse:not(.show) {
    display: none;
}

.navbar-collapse.navbar-admin {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
}

.header-right-icons .nav-link.icon {
    margin: 5px;
    padding: 9px !important;
    text-align: center;
    height: 2.5rem;
    font-size: 1.2rem;
    position: relative;
    color: #6c55fc;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

.nav-link.icon {
    margin: 5px;
    padding: 11px;
    text-align: center;
    height: 2.5rem;
    font-size: 1.2rem;
    position: relative;
}

.header-right-icons a {
    padding-top: 9px;
}

a.icon {
    text-decoration: none;
    cursor: pointer;
}

.nav-link {
    min-width: 2rem;
    transition: 0.3s color;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: #6c55fc;
}

.nav-link {
    display: block;
    padding: 0.2rem 0.9rem;
}

.dropdown .avatar.profile-user {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
}

.avatar {
    background: var(--primary02) no-repeat center/cover;
}

.brround {
    border-radius: 50% !important;
}

.cover-image {
    background-size: cover !important;
}

.avatar {
    display: inline-block;
    position: relative;
    text-align: center;
    color: #fff;
    font-weight: 500;
    vertical-align: bottom;
    font-size: 0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.avatar {
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    display: inline-block;
    position: relative;
    text-align: center;
    color: #fff;
    font-weight: 500;
    vertical-align: bottom;
    font-size: 0.875rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

img {
    max-width: 100%;
}

.nav-link.icon svg {
    color: #6c55fc;
    width: 20px;
    position: relative;
}

.icon svg {
    vertical-align: -1px;
}

.responsive-navbar.navbar-admin .profile-1 .dropdown-item {
    padding: 0.75rem 1.75rem !important;
}

.responsive-navbar.navbar-admin .dropdown-menu .dropdown-item {
    border-bottom: 1px solid #e9edf4;
}

.responsive-navbar.navbar-admin .dropdown-menu .dropdown-item:last-child {
    border-bottom: 0;
}

.navbar-admin .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem !important;
    clear: both;
    font-weight: 400;
    color: #6e84a3;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    border-bottom-color: currentcolor;
    border-bottom-style: none;
    border-bottom-width: 0px;
}


.fs-10 {
    font-size: 10px !important;
}

.fs-11 {
    font-size: 11px !important;
}

.fs-12 {
    font-size: 12px !important;
}

.fs-13 {
    font-size: 13px !important;
}

.fs-14 {
    font-size: 14px !important;
}

.fs-15 {
    font-size: 15px !important;
}

.fs-16 {
    font-size: 16px !important;
}

.fs-18 {
    font-size: 18px !important;
}

.fs-19 {
    font-size: 19px !important;
}

.fs-17 {
    font-size: 17px !important;
}

.fs-20 {
    font-size: 20px !important;
}

.fs-23 {
    font-size: 23px !important;
}

.fs-25 {
    font-size: 25px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.fs-40 {
    font-size: 40px;
}

.fs-50 {
    font-size: 50px;
}

.fs-100 {
    font-size: 100px;
}

.drop-heading {
    padding: 1rem 1rem;
    color: #8f9daf;
}

.responsive-navbar .dropdown-menu {
    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
    border: 1px solid #e9edf4;
}

small,
.small {
    font-size: 80%;
    font-weight: 400;
}

.dropdown-icon {
    color: #8f9daf;
    margin-right: 0.5rem;
    margin-left: -0.5rem;
    width: 1em;
    display: inline-block;
    text-align: center;
    vertical-align: -1px;
}

.responsive-navbar .profile-1 .dropdown-item .dropdown-icon {
    font-size: 16px;
    color: #6c55fc;
    position: relative;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    color: #6c55fc;
}

.dropdown-item:hover,
.dropdown-item:focus,
.dropdown-item.active,
.dropdown-item:active {
    text-decoration: none;
    background-color: rgba(108, 95, 252, 0.05);
}

@media (min-width: 992px) {
    .sticky {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .horizontal-main.ps {
        overflow: visible !important;
    }
}

.app-sidebar.horizontal-main {
    padding-top: 0px;
}

@media (min-width: 992px) {
    .app-sidebar {
        transition: none;
    }

    .horizontal-main {
        position: relative !important;
        bottom: initial !important;
    }

    .horizontal-main {
        z-index: 7 !important;
    }

    .app-sidebar {
        padding-top: 55px;
    }

    .horizontal-main {
        position: relative;
        margin: 0 auto;
    }
}

.app-sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    color: #282f53;
    z-index: 99;
    background: #fff;
    -webkit-box-shadow: 0 8px 24px rgba(168, 180, 208, 0.1);
    box-shadow: 0 8px 24px rgba(168, 180, 208, 0.1);
    -webkit-transition: left 450ms ease, width 450ms ease;
    -o-transition: left 450ms ease, width 450ms ease;
    border-right: 1px solid #e9edf4;
}

@media (min-width: 992px) {

    .hor-header .container,
    .horizontal-main .container,
    .main-content.hor-content .page-admin .container {
        max-width: 85% !important;
        padding: 0;
    }
}

@media (min-width: 992px) {
    .main-sidemenu {
        overflow: hidden;
    }
}

@media (min-width: 992px) {
    .main-sidemenu {
        margin-top: 0;
    }
}



@media (min-width: 576px) {
    .page-admin .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .page-admin .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .page-admin .container {
        max-width: 960px;
    }
}

@media (min-width: 1280px) {
    .page-admin .container {
        max-width: 1140px;
    }
}



@media (min-width: 992px) {

    .main-sidemenu .slide-left,
    .main-sidemenu .slide-right {
        background: #fff;
        border: 1px solid #e9edf4;
    }

    .main-sidemenu .slide-left {
        left: 23px;
    }

    .main-sidemenu .slide-left,
    .main-sidemenu .slide-right {
        position: absolute;
        top: 9px;
        padding: 6px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        border: 1px solid #e9edf4;
        border-radius: 50px;
    }

    @media (min-width: 992px) {

        .main-sidemenu .slide-left,
        .main-sidemenu .slide-right {
            background: #fff;
            border: 1px solid #e9edf4;
        }
    }

    @media (min-width: 992px) {

        .main-sidemenu .slide-left,
        .main-sidemenu .slide-right {
            position: absolute;
            top: 9px;
            padding: 6px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            cursor: pointer;
            border: 1px solid #e9edf4;
            border-radius: 50px;
        }
    }

    @media (min-width: 992px) {
        .main-sidemenu .slide-right {
            right: 20px;
        }
    }

    .side-menu {
        display: flex;
        overflow: hidden;
        padding: 0;
    }
}

.side-menu {
    margin-bottom: 0;
    padding: 0 10px 0 10px;
}

ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

@media (min-width: 992px) {
    .side-menu .slide {
        margin: 0 3px;
    }
}

@media (min-width: 992px) {
    .side-menu .slide .side-menu__item.active {
        border-right: none;
    }

    .side-menu>li>a.active {
        color: var(--primary-bg-color);
    }

    .side-menu>li>a {
        display: flex;
        margin: -3px -4px !important;
        text-decoration: none;
        position: relative;
        color: #7b8191;
        padding: 16px 16px !important;
        font-weight: 400;
    }
}

.side-menu .side-menu__icon {
    color: var(--primary-bg-color);
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    margin-right: 8px;
    position: relative;
}

@media (min-width: 992px) {
    .app-sidebar.horizontal-main .side-menu .sub-category {
        display: none;
    }
}

.side-menu .sub-category:not(:first-child) {
    margin-top: 0.75rem;
}

.side-menu .sub-category {
    color: #74829c;
    margin-bottom: 0.5rem;
    padding: 12px 30px 2px 20px;
    margin-bottom: 0;
    white-space: nowrap;
    position: relative;
}

.side-menu__label {
    white-space: nowrap;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-top: 3px;
}

.side-header {
    display: flex;
    border-bottom: 1px solid #e9edf4;
    border-right: 1px solid #e9edf4;
    padding: 19px 17px;
    align-items: center;
    -webkit-transition: left 0.3s ease, width 450ms ease;
    -o-transition: left 0.3s ease, width 450ms ease;
    transition: left 0.3s ease, width 450ms ease;
    height: 75px;
}

@media (min-width: 992px) {
    .side-header {
        width: 270px;
        left: 0;
        right: 0;
        top: 0;
        position: fixed;
        z-index: 99;
        background: #fff;
    }
}

@media (min-width: 992px) {
    .side-header {
        display: none;
    }
}

.hor-content {
    min-height: calc(100vh - 56px) !important;
}

.page-admin {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
}

.page-main {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

.page-admin {
    margin: 0;
    font-family: "IBM Plex Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #282f53;
    text-align: left;
    background-color: #f0f0f5;
}

.page-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin: 1.5rem 0rem 1.5rem;
    -ms-flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    border-radius: 7px;
    position: relative;
    min-height: 50px;
    border: 1px solid transparent;
    border-radius: 5px;
}

.page-title {
    margin: 0;
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    margin-bottom: 0.2rem;
}

.page-header .breadcrumb {
    font-size: 13px;
    padding-left: 0 !important;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    list-style: none;
    border-radius: 3px;
    right: 0;
    padding: 0;
    padding-left: 0px;
    color: #fff;
    background: none;
}

.breadcrumb-item {
    display: -ms-flexbox;
    display: block !important;
}

.breadcrumb-item a {
    color: #76839a;
}

a {
    -webkit-text-decoration-skip-ink: auto;
    text-decoration-skip-ink: auto;
}

a {
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: inherit !important;
    background-clip: border-box;
    border-radius: 7px;
    box-shadow: 0 4px 25px 0 rgba(168, 180, 208, 0.1);
}

.card {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
}

.card-header:first-child {
    border-radius: 7px 7px 0 0;
}

.card-header {
    background: none;
    background-color: rgba(0, 0, 0, 0);
    padding: 1.2rem 1.5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
    background-color: transparent;
    border-bottom: 1px solid #e9edf4;
    letter-spacing: 0.2px;
}

.card-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.card-header .card-title {
    margin-bottom: 0;
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 25px;
    margin: 0;
    position: relative;

}