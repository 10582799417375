#berita {
    & .berita_list {
        img {
            transition: 0.25s all ease-in-out;
            width: 100%;
            border-radius: 12px;
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }

        .col-3 .lazyload-wrapper {
            overflow: hidden;
            border-radius: 12px;
            border: 1px solid #dee2e6 !important;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        }

        &:hover {
            img {
                transform: scale(1.1);
                transition: 0.25s all ease-in-out;
            }
        }
    }
}

#berita_info {
    & img.img-thumbnails {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 16 / 9;
    }

    & .bagikan {
        & svg {
            font-size: 25px;
        }
    }
}