footer {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: #ef9f13;
    color: white;

    @media screen and (max-width: 768px) {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    h6 {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: 2px;
    }

    img {
        max-height: 100px;
        object-fit: cover;
    }

    a.text-muted:hover {
        color: rgba(255, 255, 255, 0.7) !important;
        transition: 0.2s all cubic-bezier(0.215, 0.610, 0.355, 1);
    }
}