.admin.main-content-body-chat {
    flex-direction: column;
    background-color: white;
    z-index: 9999;
}

.main-chat-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 12px;
    padding-top: 15px;
    border-bottom: 1px solid #e9edf4;
}

.main-img-user {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.main-chat-msg-name {
    margin-left: 15px;
}

.main-chat-msg-name h6 {
    margin-bottom: 0px;
    font-size: 15px;
    font-weight: 700;

}

.dot-label.bg-success {
    background: #09ad95 !important;
}

.dot-label {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    margin-right: 5px;
}

.main-chat-msg-name small {
    font-size: 12px;
    color: #76839a;
}

.main-chat-header .nav {
    margin-left: auto;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.main-chat-header .nav-link {
    display: block;
    padding: 0;
    color: #76839a;
    font-size: 16px;
    text-align: center;
    padding: 0.35rem 0.75rem;
    margin: 0 2px;
}

.main-chat-body {
    position: relative;
    padding-bottom: 0;
    height: 400px;
    overflow-y: scroll;
}

.main-chat-body .content-inner {
    padding: 20px;
}

.main-chat-time:first-of-type {
    margin-top: 0;
}

.main-chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0;
    margin-top: 20px;
}

.main-chat-time::before {
    content: "";
    position: absolute;
    width: 40% !important;
    height: 1px;
    left: 0;
    right: 0;
    background-color: #e9e9ef;
    top: 10px;
}

.main-chat-time span {
    position: relative;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #fff;
    padding: 25px;
    border-radius: 4px;
}

.main-chat-time::after {
    content: "";
    position: absolute;
    width: 40% !important;
    height: 1px;
    left: auto;
    right: 0;
    background-color: #e9e9ef;
    top: 10px;
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
}

.main-img-user {
    display: block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
}

.main-img-user img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;

}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: rgba(108, 95, 252, 0.2);
    color: #000;
    position: relative;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.main-msg-wrapper {
    padding: 10px 15px;
    background-color: #f0f0f5;
    font-size: 13px;
    margin-bottom: 10px;
    display: inline-block;
    border-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    position: relative;
}

.main-chat-body .media-body>div:last-child {
    font-size: 11px;
    color: #76839a;
    display: flex;
    align-items: center;
}

.main-chat-body .media-body>div.main-msg-time-wrapper:not(:last-child) {
    display: none;
}

.main-chat-body .media+.media {
    margin-top: 20px;
}

.media-body {
    zoom: 1;
    overflow: hidden;
    width: auto;
    -ms-flex: 1;
    flex: 1;
}

.media-body,
.media-left,
.media-right {
    display: block;
}

.main-chat-body .media-body {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (min-width: 576px) {
    .main-chat-body .media-body {
        margin-right: 55px;
    }
}

.main-chat-body .media.flex-row-reverse .media-body {

    margin-left: 0;
    margin-right: 20px;
    align-items: flex-end;

}

.chat-left .main-msg-wrapper {
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}

.main-msg-wrapper {
    padding: 10px 15px;
    background-color: #f0f0f5;
    font-size: 13px;
    margin-bottom: 5px;
    display: inline-block;
    border-radius: 20px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    position: relative;
}

.main-chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 60px;
    padding-right: 20px;
    border-top: 1px solid #e9edf4;
    background-color: #fff;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 7px 7px;
}

@media (min-width: 992px) {
    .main-chat-footer {
        position: relative;
        bottom: auto;
        left: auto;
        right: auto;
    }
}

.main-chat-footer .form-control {
    flex: 1;
    margin: 0 10px;
    padding-left: 12px;
    padding-right: 0;
    border-width: 0;
    border: 1px solid #e9edf4;
}

@media (min-width: 576px) {
    .main-chat-footer .form-control {
        margin-left: 20px;
    }
}

@media (min-width: 768px) {
    .main-chat-footer .form-control {
        margin: 0 20px;
    }
}

@media (min-width: 576px) {
    .main-chat-footer .nav-link {
        display: block;
    }
}

.main-chat-footer .nav-link {
    padding: 0;
    color: #76839a;
    font-size: 16px;
    display: none;
}

.brround {
    border-radius: 50% !important;
}