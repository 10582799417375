.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: red;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px;
    display: none;
    transition: 0.25s all ease-in-out;

    &.scrolled {
        display: block;
        transition: 0.25s all ease-in-out;
    }

}

.scroll-top:hover {
    background-color: #555;
}