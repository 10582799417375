.navbar {
    background: linear-gradient(to top, transparent 0%, rgb(0, 0, 0, 0.2) 50%);
    padding-left: 75px !important;
    padding-right: 75px !important;
    transition: 0.25s background ease-in-out;

    &.scrolled {
        background: rgb(239, 159, 19, 1);
        transition: 0.25s background ease-in-out;
    }

    // & .dropdown-toggle::after {
    // display: none;
    // }

    @media screen and (max-width: 768px) {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.nav-link {
    font-weight: 700;

    &.active {
        border-bottom: 3px solid #ee0404;
    }
}

.logo_navbar {
    @media screen and (max-width: 786px) {
        max-width: 40px;
    }

    @media screen and (min-width: 787px) {
        max-width: 55px;
        margin-left: 50px;
    }
}