#faq {
    background-color: #eee;
}

.title_desc {
    background-image: linear-gradient(276.65deg, #C8BDFF -80.13%, #B5EDFF -26.05%, #00CBFF 16.64%, #6721FF 64.25%, #C8BDFF 115.06%);
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    color: #b6b9ce;
    font-weight: 700;
    letter-spacing: .06em;
    text-transform: uppercase;
}