#partner {
    background-color: #f7f8fd;
}

.partner-item {
    transition: 0.25s all ease-in-out;

    & img {
        aspect-ratio: 1 / 1;
        object-fit: cover;
    }

    &:hover {
        transform: scale(1.2);
        transition: 0.25s all ease-in-out;
    }
}